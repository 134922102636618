<template>
  <div class="content-container">
    <PageTitle :title="$t('SETTINGS.MY_ACCOUNT')" class="page-title">
      <template #extra-content>
        <ActionButton :icon="require('../../../assets/icons/icn_delete.svg')" class="delete-button" :text="$t('SETTINGS.DELETE_ACCOUNT')" @click="deleteAccountWarning()" />
      </template>
    </PageTitle>

    <h1 class="page-section-title">{{ $t('EDIT_DATA') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')" :route-name="getCorrectLink(PAGES.SETTINGS) "/>


    <!-- Form -->
    <FormWrapper :send-form-data="validateData" class="page-form">
      <template #form-content>

        <!-- To help auto complete on chrome functionally useless -->
        <input type="text"
               name="email"
               value="..."
               autocomplete="username email"
               style="display: none;">


        <!-- Email -->
        <InputField :field-title="$t('EMAIL_ADDRESS')"
                    :field-name="'email'"
                    :rules="'required'"
                    :type="'email'"
                    :bind-value="true"
                    :placeholder="$t('EMAIL_ADDRESS')"
                    :cy-selector="'email-input'"
                    :input-icon="require('../../../assets/icons/icn_email.svg')"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :api-error-text="emailApiError"
                    :value="emailAddress"

                    class="form-element"/>

        <!-- Current password -->
        <InputField :field-title="$t('CURRENT_PASSWORD')"
                    :field-name="'currentPassword'"
                    :rules="'required'"
                    :type="'password'"
                    :autocomplete="'current-password'"
                    :placeholder="$t('PASSWORD')"
                    :cy-selector="'name-input'"
                    :input-icon="require('../../../assets/icons/icn_password.svg')"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :api-error-text="currentPasswordApiError"
                    class="form-element"/>

        <!-- Password field -->
        <PasswordConfirmation :field-title="`${$t('NEW_PASSWORD')}`"
                              :field-title-confirm="`${$t('REPEAT_PASSWORD')}`"
                              :placeholder="$t('PASSWORD')"
                              :placeholder-confirm="$t('REPEAT_PASSWORD')"
                              :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                              :input-icon="require('../../../assets/icons/icn_password.svg')"
                              :field-info="$t('PASSWORD_INFO')"
                              :show-error="false"
                              :required="false"
                              :autocomplete="'new-password'"
                              :debounce="0"
                              :inline-subtitle="`(${$t('OPTIONAL')})`"

                              :custom-text-error-class="'text-error-right'"
                              :api-error-text="''"
                              :display-api-error="false"
                              class="form-element"/>
      </template>

      <template #button-submit>
        <button type="submit" class="pink-button button-submit">
          {{ $t('SAVE_CHANGES') }}
        </button>
      </template>

    </FormWrapper>

    <!-- Delete account modal -->
    <BasicModal v-if="isBasicVersionDisplayed">
      <template #content>
        <p class="error-text"> {{ errorText }} </p>
        <InputField field-title="Wachtwoord"
                    field-name="PasswordModal"
                    autocomplete="off"
                    type="password"
                    class="password-align"
                    :input-icon="require('../../../assets/icons/icn_password.svg')"
                    :on-update="paswordModalChange"
                    :input-full-width="true" />
      </template>
    </BasicModal>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'
import FormWrapper from '@/components/yo-form/FormWrapper'
import InputField from '@/components/yo-form/InputField'
import PasswordConfirmation from '@/components/yo-form/PasswordConfirmation'
import { SET_BASIC_MODAL_DATA, SET_TOAST_DATA } from '@/store/mutations'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { useStore } from 'vuex'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import { useRoute, useRouter } from 'vue-router'
import { ROUTE_NAMES_AUTH } from '@/router/modules/auth'
import { EDIT_TEACHER_ACCOUNT_DETAILS, DELETE_TEACHER_ACCOUNT, GET_TEACHER_SETTINGS } from '@/store/modules/teacherPortal/actions'
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import ActionButton from '@/components/table/ActionButton'
import { getCorrectLink, PAGES } from '@/utils/helpers/RouterSelector'
import { LOGOUT, CLEAR_TEACHER } from '@/store/modules/auth/actions'
import { useI18n } from 'vue-i18n'

export default {
  name: 'EditDetails',
  components: {
    BasicModal,
    PasswordConfirmation,
    FormWrapper,
    BackButton,
    PageTitle,
    InputField,
    ActionButton,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const settings = computed(() => store.getters.getTeacherSettings)
    const { t } = useI18n()
    const emailAddress = computed(() => settings.value.email ?? '')
    store.dispatch(GET_TEACHER_SETTINGS)
    const modalController = ref(new ModalController())
    const currentPasswordApiError = ref('')
    const emailApiError = ref('')
    const activeModal = computed(() => store.getters.getActiveBasicModal)

    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.DELETE_ACCOUNT
    )

    const PasswordDeleteModal = ref('')
    const errorText = ref('')
    /** Delete **/
    function deleteAccountWarning() {
      // TODO: Add the executeCallback function.
      const payload = {
        modalOptions: {
          modalContent: BASIC_MODAL_CONTENT.DELETE_ACCOUNT,
          cancelCallback: null,
          executeCallback: onAccountDeletion
        },
        activeModal: MODAL_NAMES.DELETE_ACCOUNT
      }
      store.commit(SET_BASIC_MODAL_DATA, payload)
    }

    function onAccountDeletion() {
      // TODO: Before deleting the account, we need to verify that the password is correct.
      deleteAccount().then(response => {
        if (response) {
          modalController.value.resetActiveModal()
          store.dispatch(LOGOUT)
          router.push({ name: ROUTE_NAMES_AUTH.REGISTER_AS })
          store.commit(SET_TOAST_DATA, { src: require('@/assets/icons/icn_successful.svg'), text: 'Account succesvol verwijderd' })
        } else {
          errorText.value = 'Verkeerd wachtwoord'
        }
      })
    }

    function deleteAccount() {
      const platform = route.meta.platform
      // TODO: Make API call and return the promise.
      return store.dispatch(DELETE_TEACHER_ACCOUNT, PasswordDeleteModal.value).then(response => {
        if (response.error) {
          return false
        } else {
          return true
        }
      })
    }

    /** Form **/
    function validateData(data) {
      const payload = { oldPassword: data.currentPassword }
      if (data.email !== emailAddress.value) {
        payload.email = data.email
      }
      if (data.password && data.password !== '') {
        if (data.password !== data.confirmation) {
          return
        }
        payload.newPassword = data.password
      }

      store.dispatch(EDIT_TEACHER_ACCOUNT_DETAILS, payload)
        .then((result) => {
          if (result.error === 'invalid_password') {
            currentPasswordApiError.value = 'Het opgegeven (oude) wachtwoord is niet geldig voor de ingelogde gebruiker.'
          } else if (result.error === 'email_already_in_use') {
            emailApiError.value = t('API_ERRORS.EMAIL_ALREADY_IN_USE')
          } else if (!result.error) {
            router.push({ name: getCorrectLink(PAGES.SETTINGS) })
            store.commit(SET_TOAST_DATA, { src: require('@/assets/icons/icn_successful.svg'), text: 'Wachtwoord succesvol gewijzigd' })
          }
        })
    }

    function paswordModalChange(value) {
      PasswordDeleteModal.value = value
    }

    return {
      errorText,
      currentPasswordApiError,

      /** Delete **/
      deleteAccountWarning,
      PasswordDeleteModal,
      isBasicVersionDisplayed,
      paswordModalChange,

      /** Form **/
      validateData,
      getCorrectLink,
      PAGES,
      emailAddress,
      emailApiError
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.section-title {
  margin-bottom: rem(16);
}

.password-align {
  margin-top: rem(20);
}

.delete-button {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: rem(10) rem(21);
  //width: rem(236);

  .icon {
    margin-right: rem(15);
    width: rem(15);
  }
}

.error-text{
  margin-top: rem(10);
  color: red;
}
</style>
